import { Button, Row, Col, Modal } from 'antd';
import { func, object } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { t } from '../../../utils/helperFunctions';

export const LandIng = ({ history, setShowLogin }) => {
  const openLogin = () => {
    setShowLogin(true);
  };

  const showInfoModal = () => {
    Modal.info({
      title: 'Aviso Importante',
      content: (
        <p>
          La creación de briefs en la plataforma v2 ha sido deshabilitada. Por
          favor, usen la versión 3&nbsp;
          <a
            href="https://prd-app.influtech.net/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://prd-app.influtech.net/&nbsp;
          </a>
          para continuar. Las marcas blancas no se verán afectadas. Para
          cualquier consulta adicional, contácte al equipo técnico.
        </p>
      ),
      onOk() {},
      icon: null,
    });
  };

  const openBrief = () => {
    const { whiteBrand } =
      JSON.parse(localStorage.getItem('conf-localhost')) || {};

    if (whiteBrand === '001') {
      showInfoModal();
    } else {
      history.push('/brief');
    }
  };

  return (
    <div className="landing">
      <Col>
        <Row
          type="flex"
          justify="center"
          className="mb-25 ml-25 send-container"
        >
          <Col>
            <Button
              type="primary"
              block
              onClick={openBrief}
              className="forgot-password-button"
            >
              {t('briefs.createBrief')}
            </Button>
          </Col>
        </Row>
        <Row type="flex" justify="center" className="ml-25  send-login">
          <Col>
            <Button
              type="link text-left"
              block
              onClick={openLogin}
              className="login-landing"
            >
              {t('auth.logIn')}
            </Button>
          </Col>
        </Row>
      </Col>
      <div className="logo-fluvip" />
    </div>
  );
};

LandIng.propTypes = {
  history: object.isRequired,
  setShowLogin: func.isRequired,
};

export const SimpleLandIng = LandIng;

const ShowTheLocationWithRouter = withRouter(LandIng);

export default connect()(
  reduxForm({
    form: 'logInForm',
  })(ShowTheLocationWithRouter)
);
